import React from 'react'

function Loading({show}) {
  return show && (
    <div className='loader__section'>
        <img className="img-fluid" src="../images/imgpsh_fullsize_anim.gif" width="" height="" alt="Logo" />
    </div>
  )
}

export default Loading;