import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/main.css';

import FrontendLayout from './layouts/FrontendLayout';
import ProductsLayout from './layouts/ProductsLayout';
import ContactUsLayout from './layouts/ContactUsLayout';
import ServicesLayout from './layouts/ServicesLayout';
import PortfolioLayout from './layouts/PortfolioLayout';
import ExpertiseLayout from './layouts/ExpertiseLayout';
import PostLayouts from './layouts/PostLayouts';
import PostDetailsLayout from './layouts/PostDetailsLayout';
import NavbarComponent from './components/NavbarComponent';
import FooterComponents from './components/FooterComponents';
import ErrorLayout from './layouts/ErrorLayout';
import Loading from './layouts/Loading';

axios.defaults.baseURL = "https://ldsensnewgen.leidsens.com/wp-json/leidsens/v1";
// axios.defaults.baseURL = "http://leidsens.local/wp-json/leidsens/v1";
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Accept'] = 'application/json';
// axios.defaults.withCredentials = true;

function App() {
  const [loading, setLoading] = useState(false);

  axios.interceptors.request.use((config) => {
    setLoading(true);
    return config;
  },(error)=>{
    return Promise.reject(error);
  });
  axios.interceptors.response.use((config) => {
    setLoading(false);
    return config; 
  },(error)=>{
    return Promise.reject(error);
  });

  return (
    <BrowserRouter>
      <NavbarComponent />
      <Loading show={loading} />
      <Routes>
        <Route path="/" element={<FrontendLayout />} />
        <Route path="/our-products" element={<ProductsLayout />} /> 
        <Route path="/contacts" element={<ContactUsLayout />} />  
        <Route path="/services" element={<ServicesLayout />} />
        <Route path="/expertise" element={<ExpertiseLayout />} />
        <Route path="/portfolio" element={<PortfolioLayout />} />   
        <Route path="/blog" element={<PostLayouts />} />     
        <Route path="/blog/:slug" element={<PostDetailsLayout />} />   
        <Route path="*" element={<ErrorLayout />} />   
      </Routes>
      <FooterComponents />
    </BrowserRouter>
  );
}

export default App;
