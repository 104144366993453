import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function FooterComponents() {
    const [footerMenu, setFooterMenu] = useState([]);
    const getApiData = async () => {
        const res = await axios.get('/footermenu');
        setFooterMenu(res.data.recive_data[0]);
        // console.log(res.data.recive_data);  
    }
    useEffect(() => {
        getApiData();
    }, []);
    const d = new Date();
    let year = d.getFullYear();
    return (
        <footer className="siteFooter">
            <section className="siteFooter__top">
                <div className="container">
                    <NavLink to='/' className="footer-logo">
                        <img src="./images/footerlogo.png" alt="" className="img-fluid" />
                    </NavLink> 
                    <div className="contact_info">
                        <a href="mailto:info@leidsens.com">info@leidsens.com</a>
                    </div>
                    <ul className="footer_navber">
                        {
                            footerMenu.map((post, i) => {
                                const { title, slug } = post;
                                // alert(title);
                                return (
                                <li key={i}>
                                    {(title != 'Home')?(<NavLink to={'/' + slug}  >{title}</NavLink>):(<NavLink to='/' >{title}</NavLink>)}
                                    {/* <NavLink to={'/' + slug}  >{title}</NavLink> */}
                                </li>
                                );
                            })
                        }
                    </ul>
                    <ul className='footerproductLogos'> 
                        <li>
                            <a href="https://www.leidsens.com/dev/pts/" target='blank'> 
                                <img src="./images/ptms_logo.png" alt="" className="img-fluid" />
                            </a>
                        </li>
                        <li className='tms_logo'>
                            <a href="https://leidsens.com/tms-demo/public/" target='blank'>
                                <img src="./images/logo_tms.png" alt="" className="img-fluid" />
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
            <hr />

            <section className="siteFooter__copytxt">
                <p>© 2019-{year} Leidsens. All Rights Reserved.</p>
            </section>
        </footer>
    )
}

export default FooterComponents