import React from 'react';

const ErrorLayout = () => {
  return (
    <>
        <section className="error_details_sec">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <h3>Oops Something Went Wrong. </h3>
                        <h2>404 Error!</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <img src="./images/error.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ErrorLayout;