import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Slider from "react-slick";

const PartnersLogoComponent = () => {
    const [fullWidth, setFullWidth] = useState([]);
    const [gallery, setGallery] = useState([]);
    
    const getApiData = async () => {
        const res = await axios.get(`/pagecontent`);
        setFullWidth(res.data.recive_data[7].flexible_column_content);
        setGallery(res.data.recive_data[8].gallery_section);
        //   console.log(res.data.recive_data[8].gallery_section);  
    }

    useEffect(() => {
      getApiData();
    }, []);

    var settingsss = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        responsive: [
            {
                breakpoint: 5000, // a unrealistically big number to cover up greatest screen resolution
                settings: "unslick"
            },
            {
                breakpoint: 1200,
                settings : {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 999,
                settings : {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings : {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    }
    
    return (
        <section className="work_logo_sec">
            <div className="container">
                <div className="work_text_wrap">
                    <h2>Who We Work With</h2>
                    {
                        fullWidth?(<p dangerouslySetInnerHTML={{__html: fullWidth.content}}></p>):null
                    }
                    <Slider {...settingsss}>
                        {
                            gallery?(
                                gallery.map( (post, i) => {
                                    const { add_image, external_link } = post;
                                    return(
                                        <NavLink to={external_link} key={i}>
                                            <img src={add_image.image_path} alt="" />
                                        </NavLink>
                                    )
                                })                             
                            ):null
                        }
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default PartnersLogoComponent;