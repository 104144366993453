import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

function ProductsLayout() {
    const [products, setProducts] = useState([]);
    const pathName = window.location.pathname;
    const slugName = pathName.slice(1);
    
    const getApiData = async () => {
        const res = await axios.get(`/pagecontent?page_slug=${slugName}`);
        setProducts(res.data.recive_data[0].three_column_image_content);
        // console.log(res.data.recive_data[0].three_column_image_content);  
    }
    useEffect(() => {
        getApiData();
    }, []);
    return (
        <>
            <SeoComponents />
            <BannerComponents />
            <section className="ourProducts__sec margin-t-b">
                <div className="container">
                    <h2>Our Products</h2>
                    <div className="row">           
                        {
                        products.map((post, index) => {
                            const { title, sub_title,content, image_details, extra_link } = post;
                            return (                
                            <div className="col-12 col-md-6 col-lg-4 card_body_sec" key={index}>
                                <div className="card text-center">
                                    <NavLink to={ extra_link.url }>
                                        {
                                            image_details?(<img src={image_details.image_path} alt="..." />):(
                                                <img className="img-fluid" src="./images/no-image.jpg"  alt="" />
                                            )
                                        }
                                    </NavLink>
                                    <div className="card-body">
                                        {
                                            sub_title?(<h6>{ sub_title }</h6>):("")
                                        } 
                                        <h4><NavLink to={ extra_link.url } >{title}</NavLink></h4>                                        
                                        {
                                            content?(<p>{ content }</p>):("")
                                        }
                                        {/* <p dangerouslySetInnerHTML={{__html: content}}></p>                                  */}
                                    </div>                                    
                                </div>
                            </div>
                            );
                        })
                        }          
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductsLayout