import React, { useEffect, useState } from 'react';
import axios from 'axios';

function BestfitComponents() {
  const [bestFit, setBestFit] = useState([]);

  const pathName = window.location.pathname;
  const slugName = pathName.slice(1);

  const getApiData = async () => {
    if (!slugName) {
      const res = await axios.get('/pagecontent');
      setBestFit(res.data.recive_data[5]);
      // console.log(res.data.recive_data[5]);  
    }else{
      const res = await axios.get(`/pagecontent?page_slug=${slugName}`);
      setBestFit(res.data.recive_data[2]); 
    }
  }
  useEffect(() => {
    getApiData();
  }, []);

  const data = bestFit.flexible_column_content;
  return (
    <section className="project_discuss_info_sec">
      {
        data ? (<div className="container" dangerouslySetInnerHTML={{ __html: data.content }}></div>) : null
      }
    </section>
  )
}

export default BestfitComponents;