import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

const PostLayouts = () => {
  const [postdata, setPostdata] = useState([]);
  const [limit, setLimit] = useState(6);
  const [totalPost, setTotalPost] = useState(0);

  const getApiData = async () => {
    const res = await axios.get(`/post`);
    setPostdata(res.data.recive_data.post_data);
    setTotalPost(res.data.recive_data);
    // console.log(res.data.recive_data.testimonial_data );  
  }
  useEffect(() => {
    getApiData();
  }, []);
  let totalPage = totalPost.total_post;
  let pages = (totalPage - limit);
  const loadMore = () => {
    setLimit( (preVal) => preVal + 6);
  }

  return (
    <>
      <SeoComponents />
      <BannerComponents />
      <section className="blog_details_sec margin-t-b">
        <div className="container">
          <div className="row">           
              {
                postdata.slice(0, limit).map((post, index) => {
                  const { title, content, add_image, post_slug } = post;
                  return (                
                    <div className="col-12 col-md-6 col-lg-4 card_body_sec" key={index}>
                        <div className="card">
                          <NavLink to={"/blog/"+post_slug}>
                            {
                              add_image?(<img src={add_image.image_path} alt="..." />):(
                                <img className="img-fluid" src="./images/no-image.jpg"  alt="" />
                              )
                            }
                          </NavLink>
                            <div className="card-body">
                              <h5><NavLink to={"/blog/"+post_slug}  >{title}</NavLink></h5>
                              <p dangerouslySetInnerHTML={{__html: content}}></p>
                              <NavLink className='only_blog' to={"/blog/"+post_slug} >READ MORE</NavLink>
                            </div>
                        </div>
                    </div>
                  );
                })
              }
              
              {
                (pages > 0)?(<div className="text-center my-4">
                <div className="gg1btn" onClick={loadMore} >READ MORE</div>
                </div>):null
              }           
          </div>
        </div>
      </section>
    </>
  )
}

export default PostLayouts;