import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

const NavbarComponent = () => {
    const [myMenu, setMyMenu] = useState([]);
    const getApiData = async () => {
        const res = await axios.get('/menu');
        setMyMenu(res.data.recive_data);
        // console.log(res.data.recive_data);  
    }
    useEffect(() => {
        getApiData();
    }, []);

    return (
        <section className='headerGrp'>
            <Navbar expand="lg" className="">
                <Container>
                    <Navbar.Brand href="/"><img className="img-fluid" src="../images/logo.png" width="" height="" alt="Logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span></span><span></span><span></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {
                                myMenu.map((post, i) => {
                                    const { title, slug } = post;
                                    return (
                                        <li key={i}> 
                                            <NavLink to={'/' + slug}  >{title}</NavLink>
                                        </li>
                                    );
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </section>
    )
}

export default NavbarComponent