import React, { useState } from 'react';
import { useFormik } from 'formik';
import { signUpSchema } from '../schmas';
import { redirect } from "react-router-dom";
import axios from 'axios';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

const initialValues = {
  name: "",
  company_name: "",
  email_id: "",
  phone_no: "",
  branding_identity: "",
  web_experiences: "",
  digital_marketing: "",
  messages: ""
}

const ContactUsLayout = () => {
  let submitted = false;
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      console.log(values);
      axios.post("/contactus", values)
        .then((response) => {
            submitted = true;
            if(submitted){
              document.getElementById('successMsg').style.display = 'block';
              document.getElementById('formId').style.display = 'none';
            }else{
              document.getElementById('successMsg').style.display = 'none';
              document.getElementById('formId').style.display = 'block';
            }
        })
    }
  });
  return (
    <>
      <SeoComponents />
      <BannerComponents />
      <section className='contact_form_info_sec margin-t-b'>
        <div className="container">
          <div className="contact_text_wrap">
            <strong>Best-Fit Solutions</strong>
            <h2>Feel Free To Reach Out</h2>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5 contact_form_left_sec" style={{
              backgroundImage: "url('./images/conBlue.png')",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
              <h3>Contact Us</h3>
              <ul>
                <li>
                  <div>
                    <img src="assets/images/location.png" alt="" />
                  </div>
                  <div>
                    <strong>Address</strong>
                    <p>52, B/1, Feeder Rd, Beehive Garden, <br /> Belghoria, Kolkata, West Bengal <br /> 700056, India</p>
                  </div>
                </li>
                <li>
                  <div>
                    <img src="assets/images/calll.png" alt="" />
                  </div>
                  <div>
                    <strong>Phone</strong>
                    <p><a href="tel:9804727564">098047 27564</a></p>
                  </div>
                </li>
                <li>
                  <div>
                    <img src="assets/images/emaill.png" alt="" />
                  </div>
                  <div>
                    <strong>Email</strong>
                    <p><a href="mailto:info@leidsens.com">info@leidsens.com</a></p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-12 col-md-6 col-lg-7 contact_form_right_sec">
              <div id="successMsg" className="thankyou_page" style={{'display':'none'}}>
                <div className="container">
                  <div className="thanks_post">
                    <div className="card">
                      <img src="./images/thankYou.png" alrt="Thank You" />
                      <h3>Thank You</h3>
                      <p>Your listing request has been submitted! Your business will appear on our page after your details have been approved by the site administrator.</p>
                    </div>
                  </div>
                </div>
              </div>
          
              <form className="jvuv" id='formId' onSubmit={handleSubmit}>
                <h3>Get In Touch</h3>
                <div className='row'>
                  <div className="col-12">
                    <input type="text" placeholder='Name'
                      className="form-control"
                      id="name"
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (<p className='form_error'>{errors.name}</p>) : null}
                  </div>
                  <div className="col-12">
                    <input type="text" placeholder='Company / Organization'
                      className="form-control"
                      id="company"
                      name='company_name'
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.company_name && touched.company_name ? (<p className='form_error'>{errors.company_name}</p>) : null}
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <input type="email" placeholder='Email Address'
                      className="form-control"
                      id="email"
                      name='email_id'
                      value={values.email_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email_id && touched.email_id ? (<p className='form_error'>{errors.email_id}</p>) : null}
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <input type="text" placeholder='Phone Number'
                      className="form-control"
                      id="phoneNumber"
                      name='phone_no'
                      value={values.phone_no}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phone_no && touched.phone_no ? (<p className='form_error'>{errors.phone_no}</p>) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6">
                    <label>Branding & Identity
                      <select className="form-select"
                        name="branding_identity"
                        value={values.branding_identity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Please Select</option>
                        <option>Brand Strategy</option>
                        <option>Corporate Visual Identity</option>
                        <option>Print/Marketing Assets</option>
                      </select>
                    </label>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <label>Web Experiences
                      <select className="form-select"
                        name="web_experiences"
                        value={values.web_experiences}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option >Please Select</option>
                        <option>Website Audit</option>
                        <option>Web Design & Development</option>
                        <option>Responsive/Mobile</option>
                        <option>eCommerce</option>
                        <option>Copywriting</option>
                        <option>Ongoing Support</option>
                      </select>
                    </label>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <label>Digital Marketing
                      <select className="form-select"
                        name="digital_marketing"
                        value={values.digital_marketing}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Please Select</option>
                        <option>Social Media Marketing</option>
                        <option>SEO</option>
                        <option>Content Creation</option>
                        <option>PPC Campaigns</option>
                      </select>
                    </label>
                  </div>
                </div>

                <div className="mb-3 mess_sec">
                  <textarea className="form-control" placeholder='Massage'
                    name="messages"
                    id="exampleFormControlTextarea1" rows="3"
                    value={values.messages}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.messages && touched.messages ? (<p className='form_error'>{errors.messages}</p>) : null}
                </div>
                <div className=''>
                  <button className="gg1btn" type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUsLayout;