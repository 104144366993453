import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

function AccordionComponents() {
    const [mydata, setMydata] = useState([]);

    const getApiData = async() =>{
        const res = await axios.get('/pagecontent');
        setMydata(res.data.recive_data[4].accordion_section);
        // console.log(res.data.recive_data); 
    }
    useEffect( () => {
        getApiData();
    }, []);
    
    const data = mydata.accordion_content;
    const accImg = mydata.image;

  return (
    <section className="development_info_sec margin-t">
        <div className="container-fluid">
            <h2>Our Development Process</h2>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 development_content_sec">
                    <Accordion defaultActiveKey="0">
                        {
                            data?(
                                data.map((post, accorindex) => {
                                    const { title, content } = post;
                                    return(
                                        <Accordion.Item eventKey={`${accorindex}`} key={accorindex}>
                                            <Accordion.Header>{title}</Accordion.Header>
                                            <Accordion.Body>
                                            {content}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            ):null
                        }     
                    </Accordion>
                </div>
                <div className="col-12 col-md-6 col-lg-6 development_img_sec">
                    {
                        accImg?(
                            <img src={accImg.image_path} alt="" /> 
                        ):null
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default AccordionComponents