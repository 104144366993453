import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function TwoColComponent() {
    const [datas, setDatas] = useState([]);
    
    const getApiData = async() =>{
        const res = await axios.get('/pagecontent');
        setDatas(res.data.recive_data[0]);
        // console.log(res.data.recive_data[0]);  
    }
    useEffect( () => {
        getApiData();
    }, []);
    
    const data = datas.two_column_image_content;
    return (
        <section className="two_colom_img_textSec leftImg_rightContent margin-t-b">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 img_div_sec">
                        {
                            data?(<img src={data.image_details.image_path} alt="" /> ):null
                        }
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 content_div_sec">
                        {
                            data?(<div dangerouslySetInnerHTML={{__html: data.content}}></div>):null
                        }
                        <NavLink to='/contacts' className="gg2btn">Get In Touch</NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TwoColComponent;